import React, {useState, useEffect} from 'react';
import Drawer from '@mui/joy/Drawer';
import Stack from '@mui/joy/Stack';
import DialogTitle from '@mui/joy/DialogTitle';
import ModalClose from '@mui/joy/ModalClose';
import Grid from '@mui/joy/Grid';
import Typography from '@mui/joy/Typography';
import { ReleaseAppFCadastroInterface } from '../Interface/generic';


function valueText(value: number) {
    return `$${value.toLocaleString('en-US')}`;
  }

interface propsInterface {
    open: boolean,
    setOpen: (open: boolean) => void;
    app:ReleaseAppFCadastroInterface
}

export default function InfoDrowerPage(props:propsInterface) {
    return (
        <Drawer open={props.open} onClose={() => props.setOpen(false)}>
        <Stack useFlexGap spacing={3} sx={{ p: 2 }}>
          <DialogTitle>Filters</DialogTitle>
          <ModalClose />
          {/* <CountrySelector /> */}
          <Grid
        //   spacing={1}
          container
          justifyContent="space-around"
        //   alignItems="flex-start"
        >
            <div>
                <Typography level="body-sm">Nome:</Typography>
                <Typography level="body-sm">Versão:</Typography>
                <Typography level="body-sm">Actualizado em:</Typography>
                <Typography level="body-sm">Tamanho:</Typography>
            </div>
            <div>
                 <Typography level="title-sm">{props.app.description}</Typography>
                 <Typography level="title-sm">{props.app.version}</Typography>
                <Typography level="title-sm">{props.app.created_at}</Typography>
                <Typography level="title-sm">{props.app.apk_size}</Typography>
            </div>
        </Grid>
        </Stack>
      </Drawer>
    )
}