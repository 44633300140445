import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReleaseMobilePage from "./pages/release/releaseMobile";
import PrivateRoutes from "./settings/routes/privateRoutes";
import MobileLoginPage from "./pages/authentication/login";

const AppRoutes = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route
              path="/stm/downloads/releases"
              element={
                <ReleaseMobilePage />
              }
            />
          </Route>
          <Route path="/stm/mobile/login" element={<MobileLoginPage />} />
        </Routes>
      </div>
    </Router>
  );
};

export default AppRoutes;
