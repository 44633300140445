import { AxiosError, AxiosResponse } from "axios";
import { baseApiCall } from "../lib/api";
import { ReleaseAppFCadastroInterface } from "../../components/Interface/generic";



export async function getAllReleaseFCadastroApp() {
    let releaseApp: ReleaseAppFCadastroInterface[] = [];
    releaseApp = [{apk_file:'', code:'', created_at:'', description:'', status:"", version:"", image:"", apk_size:""}]
    try{
        await baseApiCall.get<ReleaseAppFCadastroInterface>('v2/app_version/releases/').then((response:AxiosResponse) => {
            console.log("RELEASES APP ", response.data);
            releaseApp = response.data;
        }).catch((error:AxiosError) => {
            console.log("Error ao tentar pegar a pagina")
        })   
    }catch(error){
        console.error("REUEST FAIL", error)
    }
    return releaseApp
}