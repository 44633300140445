import { AxiosError, AxiosResponse } from "axios";
import { baseApiCall } from "../lib/api";
import { AuthResponse } from "../../components/Interface/generic";


interface LoginParams {
    user_name: string,
    password: string,
    remember: boolean
}

export async function AuthenticateUser(userCredentials: LoginParams){
    let authResponse: AuthResponse
    
    try{
        authResponse = {message: '', status: 0, token: ''}
        console.log("Start loading ")
        await baseApiCall.post('v2/web/auth/login/', userCredentials).then((response: AxiosResponse) => {
            authResponse = {message: response.data['message'], status: response.data['status'], token: response.data['token']};
            localStorage.setItem("token", JSON.stringify(response.data.token));
        }).catch((error: AxiosError)=> {    
            // console.error("LOGIN ERROR ", error);
            // authResponse = {message: error.response?.data['message'], status: error.response?.status, token: ''};
        })
        console.log("Monitor response ", authResponse)
        return authResponse
    } catch (e) {
        console.log("NAO FOI POSSIVEL EFETUAR A REQUISICAO ", e);
        return {message: 'Could not verify your credentials, please check your internet connection and try again.', status: 400, token: ''}
    }
}