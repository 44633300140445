
import * as React from 'react';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import { AspectRatio, Grid } from '@mui/joy';
import IconButton from '@mui/joy/IconButton';

import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'; 
import InfoIcon from '@mui/icons-material/Info';
import InfoDrowerPage from '../Widget/drawer';
import { ReleaseAppFCadastroInterface } from '../Interface/generic';


type CustomCardProps = {
    image: string;
    liked?: boolean;
    rareFind?: boolean;
    title: React.ReactNode;
    description: React.ReactNode;
    downloadURL: string;
    version: string;
    app:ReleaseAppFCadastroInterface
    openInfo: (open:boolean) => void;
  };

export default function CustomDetailsCard(props:CustomCardProps) {
    const { title, rareFind = false, liked = false, image, description, downloadURL, version, openInfo, app} = props;
    const [open, setOpen] = React.useState(false);

    function downloadAPK() {
        const link = document.createElement('a');
        link.href = downloadURL;
        link.setAttribute(
        'download',
        title+'_'+version+'.apk',
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode!.removeChild(link);
    }
    return (
       <> <Card
       variant="outlined"
       orientation="horizontal"
       size="sm"
       sx={{ 
           '&:hover': {
               boxShadow: 'lg',
               borderColor: 'var(--joy-palette-neutral-outlinedDisabledBorder)',
           },
           bgcolor: 'neutral.softBg',  mb: 1 }
       }
     >
       <CardOverflow>
         <AspectRatio
           ratio="1"
           sx={{ 
               minWidth: 70, '& img[data-first-child]': { p: 1.5 } }}
         >
           <img
             src={image}
             srcSet={image}
             loading="lazy"
             alt=""
           />
         </AspectRatio>
       </CardOverflow>
       <CardContent>
       <Grid
       //   spacing={1}
         container
         justifyContent="space-between"
       //   alignItems="flex-start"
       >
           <div>
               <Typography level="title-md">{title}</Typography>
               <Typography level="body-sm">{description}</Typography>
           </div>
           <div>
               <Grid container>
               <IconButton
                   variant="plain"
                   size="sm"
                   color={'neutral'}
                   onClick={() => setOpen(true)}
                   sx={{
                   display: { xs: 'flex', sm: 'flex' },
                   borderRadius: '50%',
                   }}
               >
                   <InfoIcon />
               </IconButton>
               <IconButton
                   variant="plain"
                   size="sm"
                   color={'neutral'}
                   onClick={() => downloadAPK()}
                   sx={{
                   display: { xs: 'flex', sm: 'flex' },
                   borderRadius: '50%',
                   }}
               >
                   <DownloadForOfflineIcon />
               </IconButton>
               </Grid>
           </div>
       </Grid>
         
       </CardContent>
     </Card>
     <InfoDrowerPage app={app} open={open} setOpen={setOpen}/></>
    )
}