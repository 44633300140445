
import WarningIcon from '@mui/icons-material/Warning';
import Alert from '@mui/joy/Alert';
import Typography from '@mui/joy/Typography';


export function AlertWarning(description: string){
    return <Alert
        key={'Warning'}
        sx={{ alignItems: 'flex-start' }}
        startDecorator={<WarningIcon />}
        variant="soft"
        color='warning'
        // endDecorator={
        // <IconButton variant="soft" color='warning'>
        //     <CloseRoundedIcon />
        // </IconButton>
        // }
    >
        <div>
        <div>Warning</div>
        <Typography level="body-sm" color='warning'>
            {description}
        </Typography>
        </div>
    </Alert>
}