import { Outlet, Navigate } from "react-router-dom";
import { isAuthenticated } from "../lib/stateControl";

const PrivateRoutes = () => {

  if (isAuthenticated()) {
    const tokenIsValid = true;

    if (tokenIsValid) {
      return <Outlet />;
    } else {
      return (
        <>
          <Navigate to="/stm/mobile/login" />
        </>
      );
    }
  } else {
    return (
      <>
        <Navigate to="/stm/mobile/login" />
      </>
    );
  }
};

export default PrivateRoutes;
