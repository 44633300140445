import * as React from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Input from '@mui/joy/Input';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import Link from '@mui/joy/Link';
import Alert from '@mui/joy/Alert';
import Divider from '@mui/joy/Divider';

import NavBar from '../../components/NavBar/navbar';

import LockPersonIcon from '@mui/icons-material/LockPerson';
import { Grid } from '@mui/joy';
import { AuthResponse } from '../../components/Interface/generic';
import { AlertWarning } from '../../components/Alert/generic';
import { AuthenticateUser } from '../../settings/requests/auth';
import { useNavigate } from 'react-router-dom';
import FooterArea from '../../components/Footer/footer';

interface FormElements extends HTMLFormControlsCollection {
    user_name: HTMLInputElement;
    password: HTMLInputElement;
    persistent: HTMLInputElement;
  }
  interface SignInFormElement extends HTMLFormElement {
    readonly elements: FormElements;
  }

export default function MobileLoginPage() {
    const [loading, setIsLoading] = React.useState(false);
    const [responseStatus, setResponseStatus] = React.useState<AuthResponse>({message: '', status: 200, token: ''});
    const navigate = useNavigate();

    async function sendAuthData(event: React.FormEvent<SignInFormElement>){
        event.preventDefault();
        setIsLoading(true);
        const formElements = event.currentTarget.elements;
        const data = {
            user_name: formElements.user_name.value,
            password: formElements.password.value,
            remember: formElements.persistent.checked,
        };
        var response: AuthResponse = await AuthenticateUser(data);
        console.log("Login response ", response)
        setResponseStatus(response);
        setIsLoading(false);
        if(response.status == 200){
            navigate('/stm/downloads/releases');
        }
    }
    return (
        <CssVarsProvider disableTransitionOnChange>
        <CssBaseline />
        <NavBar />
        <Grid container justifyContent='center'>
        <Box
              component="main"
              sx={{
                my: 'auto',
                py: 2,
                pb: 5,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: 400,
                maxWidth: '90%',
                mx: 'auto',
                borderRadius: 'sm',
                '& form': {
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                },
                [`& .${formLabelClasses.asterisk}`]: {
                  visibility: 'hidden',
                },
              }}
            >
                <Stack gap={4} sx={{ mb: 2 }}>
                        <Stack gap={1}>
                            <Typography component="h1" level="h3">
                                Entrar
                            </Typography>
                            {/* <Typography level="body-sm">
                                New to company?{' '}
                                <Link href="#replace-with-a-link" level="title-sm">
                                Sign up!
                                </Link>
                            </Typography> */}
                            <Alert variant="soft">Por motivos de segurança faça o login com os seus dados usados no aplicativo.</Alert>
                        </Stack>
                        <Divider
                            sx={(theme) => ({
                            [theme.getColorSchemeSelector('light')]: {
                                color: { xs: 'text.tertiary', md: 'text.tertiary' },
                                '--Divider-lineColor': {
                                xs: 'var(--joy-palette-divider)',
                                md: 'var(--joy-palette-divider)',
                                },
                            },
                            })}
                        >
                        </Divider>
                    <Stack gap={0} sx={{ mt: 0 }}>
                    {responseStatus.status != 200 ? AlertWarning(responseStatus.message) : <></>}
                    <form
                    onSubmit={(event: React.FormEvent<SignInFormElement>) => {
                        sendAuthData(event);
                    }}
                    >
                    <FormControl required>
                        <FormLabel>Usuário</FormLabel>
                        <Input type="text" name="user_name" />
                    </FormControl>
                    <FormControl required>
                        <FormLabel>Senha</FormLabel>
                        <Input type="password" name="password" />
                    </FormControl>
                    <Stack gap={4} sx={{ mt: 2 }}>
                        <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                        >
                        <Checkbox size="sm" label="Remember me" name="persistent" />
                        {/* <Link level="title-sm" href="#replace-with-a-link">
                            Forgot your password?
                        </Link> */}
                        </Box>
                        <Button loading={loading} type="submit" fullWidth>
                        Entrar
                        </Button>
                    </Stack>
                    </form>
                </Stack>
                </Stack>
                <FooterArea />
              </Box>
              
        </Grid>
        </CssVarsProvider>
    )
}