
import * as React from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';

import NavBar from '../../components/NavBar/navbar';
import HeaderSection from '../../components/Header/header';
import CustomDetailsCard from '../../components/Card/custom';
import { ReleaseAppFCadastroInterface } from '../../components/Interface/generic';
import { getAllReleaseFCadastroApp } from '../../settings/requests/generic';
import { CircularProgressBaseLoading } from '../../components/Spin/default';

export default function ReleaseMobilePage() {
    const [releaseData, setReleaseData] = React.useState<ReleaseAppFCadastroInterface[]>([{apk_file:'', code:'', created_at:'', description:'', status:"", version:"", image:"", apk_size:""}]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  React.useEffect(()=>{stratFunction()},[]);

  async function stratFunction() {
    setIsLoading(true);
    let releaseAppData: ReleaseAppFCadastroInterface[] = []
    releaseAppData = await getAllReleaseFCadastroApp();
    setReleaseData(releaseAppData);
    setIsLoading(false);
  };
  return (
    <CssVarsProvider disableTransitionOnChange>
    <CssBaseline />
    <NavBar />
    <Box
      component="main"
      sx={{
        height: 'calc(100vh - 55px)', // 55px is the height of the NavBar
        display: 'grid',
        // gridTemplateColumns: { xs: 'auto', md: '60% 40%' },
        gridTemplateRows: 'auto 1fr auto',
      }}
    >
      <Stack
        sx={{
          backgroundColor: 'background.surface',
          px: { xs: 2, md: 4 },
          py: 2,
          borderBottom: '1px solid',
          borderColor: 'divider',
        }}
      >
        <HeaderSection />
        {/* <Search /> */}
        {/* <PhoneDetails /> */}
        
        <Typography level="body-sm">All Releases</Typography>
        <br/>
        {/* <Filters /> */}
        {/* <Grid container> */}
        {isLoading ? <Grid justifyContent='center' textAlign='center'>
        <CircularProgressBaseLoading />
        </Grid> : <Grid container rowSpacing={1}>
          {releaseData.map((app:ReleaseAppFCadastroInterface) => {{return <Grid >
            <Stack spacing={2} sx={{ overflow: 'auto' }}>
          <CustomDetailsCard 
            image={app.image != undefined ? app.image : 'https://images.unsplash.com/photo-1507833423370-a126b89d394b?auto=format&fit=crop&w=70'}
            title={app.description + ' ' + app.version+'v'}
            description={app.created_at}
            downloadURL={app.apk_file}
            version={app.version}
            openInfo={setOpen}
            app={app}

          />
        </Stack>
          </Grid>}})}
        </Grid> }
        
        {/* </Grid> */}
      </Stack>
      {/* <Box
        sx={{
          gridRow: 'span 3',
          display: { xs: 'none', md: 'flex' },
          backgroundColor: 'background.level1',
          backgroundSize: 'cover',
          backgroundImage:
            'url("https://images.unsplash.com/photo-1569336415962-a4bd9f69cd83?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3731&q=80")',
        }}
      /> */}
      {/* <Stack spacing={2} sx={{ px: { xs: 2, md: 4 }, pt: 2, minHeight: 0 }}>
        
      </Stack> */}
      {/* <Pagination /> */}
    </Box>
  </CssVarsProvider>
  );
}